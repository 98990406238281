// npm
import React from 'react'
import { styled } from '@mui/material'

// components
import Tint from '@atoms/media/Tint'

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.secondary.main,
}))

const Video = styled('video')({
  position: 'relative',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})

interface Props {
  url: string
}

const BackgroundVideo = ({ url }: Props) => {
  return (
    <Container>
      <Video autoPlay muted loop playsInline>
        <source src={url} type="video/mp4" />
      </Video>

      <Tint />
    </Container>
  )
}

export default BackgroundVideo
