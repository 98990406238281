// npm
import React, { memo } from 'react'
import { Box, Container, Grid, styled, Typography } from '@mui/material'
import { use100vh } from 'react-div-100vh'
// src
import CmsLinkButton from '@atoms/buttons/CmsLinkButton'
import BackgroundVideo from '@molecules/media/BackgroundVideo'
import MainHeading from '@molecules/headings/MainHeading'
import Subtitle from '@molecules/headings/Subtitle'

const ContentContainer = styled(Box)({
  position: 'relative',
})

interface Props {
  keyId: string
  videoUrl: string
  mainHeading: string
  mainHeadingVariant: 'h1' | 'h2' | 'h3'
  subtitle: string
  text: string
  button: Queries.DatoCmsButton
}

const ContentSectionVideoBG = ({
  keyId,
  videoUrl,
  subtitle,
  mainHeading,
  mainHeadingVariant,
  text,
  button,
}: Props) => {
  return (
    <Box position="relative" color="white">
      <BackgroundVideo url={videoUrl} />

      <ContentContainer>
        <Container
          maxWidth={false}
          sx={{
            minHeight: use100vh(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: 1,
              height: 1,
              py: 13,
            }}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12} md={9} lg={7} xl={6}>
                <Subtitle
                  typographyProps={{ component: 'h1' }}
                  text={subtitle}
                  borderType="bottom"
                />
                <MainHeading
                  typographyProps={{
                    component: 'h2',
                    variant: mainHeadingVariant,
                    color: 'white',
                    textAlign: 'center',
                    lineHeight: '1',
                    mb: 0,
                    mt: { xs: 1, lg: 3 },
                  }}
                  lineBreaksProps={{
                    keyId: keyId,
                    text: mainHeading,
                    breakpoint: 'xs',
                  }}
                />
              </Grid>
            </Grid>

            {text && (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={11} sm={8} lg={6} xl={4}>
                  <Typography
                    component="div"
                    mt={1}
                    variant="heroText"
                    textAlign="center"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </Grid>
              </Grid>
            )}

            {button && (
              <Box mt={4}>
                <CmsLinkButton
                  button={button}
                  buttonLinkProps={{ colorVariant: 'white-text' }}
                />
              </Box>
            )}
          </Box>
        </Container>
      </ContentContainer>
    </Box>
  )
}

export default memo(ContentSectionVideoBG)
